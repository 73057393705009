export const mainNavLinks = [
  {
    name: "TV",
    path: "/tv",
    id: "a",
    children: [
      {
        name: "Xfinity",
        path: "/xfinity/cable-tv",
        id: "a1",
      },
      {
        name: "Spectrum",
        path: "/spectrum/cabel-tv",
        id: "a2",
      },
      {
        name: "DISH",
        // path: "/dish/review",
        path: "",
        id: "a3",
      },
      {
        name: "DIRECTV",
        // path: "/directtv/",
        path: "",
        id: "a4",
      },
      {
        name: "Optimum",
        // path: "/optimum/review",
        path: "",
        id: "a5",
      },
      {
        name: "Cox",
        path: "/cox/",
        id: "a6",
      },
      {
        name: "Verizon",
        // path: "/verizon/",
        path: "",
        id: "a7",
      },
      {
        name: "Best TV Providers",
        path: "",
        id: "a7",
      },
    ],
  },
  {
    name: "Internet",
    path: "/internet",
    id: "b",
    children: [
      {
        name: "Xfinity",
        path: "",
        id: "b1",
      },
      {
        name: "Spectrum",
        path: "",
        id: "b2",
      },
      {
        name: "Optimum",
        path: "",
        id: "b3",
      },
      {
        name: "Cox",
        path: "",
        id: "b4",
      },
      {
        name: "Verizon",
        path: "",
        id: "b5",
      },
      {
        name: "AT&T",
        path: "",
        id: "b6",
      },
      {
        name: "CenturyLink",
        path: "",
        id: "b7",
      },
      {
        name: "T-Mobile",
        path: "",
        id: "b8",
      },
      {
        name: "Best Internet Providers",
        path: "",
        id: "b8",
      },
    ],
  },
  {
    name: "Streaming",
    path: "/streaming",
    id: "c",
    children: [
      {
        name: "Youtube TV",
        path: "",
        id: "c1",
      },
      {
        name: "Sling TV",
        path: "",
        id: "c2",
      },
      {
        name: "Hulu",
        path: "",
        id: "c3",
      },
      {
        name: "Paramount+",
        path: "",
        id: "c4",
      },
      {
        name: "Netflix",
        path: "",
        id: "c5",
      },
      {
        name: "Amazon Prime Video",
        path: "",
        id: "c6",
      },
      {
        name: "Max",
        path: "",
        id: "c7",
      },
      {
        name: "Peacock",
        path: "",
        id: "c8",
      },
      {
        name: "Disney+",
        path: "",
        id: "c9",
      },
      {
        name: "Best On-Demand Streaming Services",
        path: "",
        id: "c10",
      },
      {
        name: "Best Live TV Streaming Services",
        path: "",
        id: "c11",
      },
    ],
  },
  {
    name: "What To Watch",
    path: "/what-to-watch",
    id: "d",
    children: [
      {
        name: "Hulu",
        path: "",
        id: "d1",
      },
      {
        name: "Max",
        path: "",
        id: "d2",
      },
      {
        name: "Amazon Prime Video",
        path: "",
        id: "d3",
      },
      {
        name: "Netflix",
        path: "",
        id: "d4",
      },
      {
        name: "Sling TV",
        path: "",
        id: "d5",
      },
      {
        name: "Disney+",
        path: "",
        id: "d6",
      },
      {
        name: "Sports",
        path: "",
        id: "d7",
      },
      {
        name: "What to Watch This Week",
        path: "",
        id: "d8",
      },
    ],
  },
  {
    name: "Resources",
    path: "/resources",
    id: "e",
    children: [
      {
        name: "Blog",
        path: "",
        id: "e1",
      },
      {
        name: "Anime",
        path: "",
        id: "e2",
      },
      {
        name: "Horror",
        path: "",
        id: "e3",
      },
      {
        name: "Holiday Guide",
        path: "",
        id: "e4",
      },
      {
        name: "Black Friday TV & Internet Deals",
        path: "",
        id: "e5",
      },
      {
        name: "Black Friday Smart TV Deals",
        path: "",
        id: "e6",
      },
      {
        name: "News",
        path: "",
        id: "e7",
      },
    ],
  },
];
