import { Helmet } from 'react-helmet';
import './App.scss';
import Layout from './components/layout/Layout';


function App() {
  return (
    <Layout/>
  );
}

export default App;
