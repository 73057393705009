import React from "react";
import { Route, Routes } from "react-router-dom";

//! Main Pages
import Home from "../pages/main/home/Home";
import Tv from "../pages/main/tv/Tv";
import Internet from "../pages/main/internet/Internet";
import Streaming from "../pages/main/streaming/Streaming";
import Resources from "../pages/main/resources/Resources";
import WhatToWatch from "../pages/main/what-to-watch/WhatToWatch";

//! Xfinity Pages
import XfinityReview from "../pages/xfinity/review/XfinityReview";
import XfinityInternet from "../pages/xfinity/internet/XfinityInternet";
import XfinityTv from "../pages/xfinity/tv/XfinityTv";
import XfinityDeals from "../pages/xfinity/deals/XfinityDeals";
import XfinityChannelLinup from "../pages/xfinity/channel-linup/XfinityChannelLinup";
import XfinityMobile from "../pages/xfinity/mobile/XfinityMobile";
import XfinityBundles from "../pages/xfinity/bundles/XfinityBundles";
import XfinityVoice from "../pages/xfinity/voice/XfinityVoice";
import XfinityAvailability from "../pages/xfinity/availability/XfinityAvailability";
import XfinityHiddenCosts from "../pages/xfinity/hidden-costs/XfinityHiddenCosts";
import XfinityCustomerService from "../pages/xfinity/customer-service/XfinityCustomerService";
import XfinityInternetReview from "../pages/xfinity/internet-review/XfinityInternetReview";
import XfinityTvReview from "../pages/xfinity/tv-review/XfinityTvReview";
import XfinityBundlesReview from "../pages/xfinity/bundles-review/XfinityBundlesReview";

//! Spectrum Pages
import SpectrumReview from "../pages/spectrum/review/SpectrumReview";
import SpectrumInternet from "../pages/spectrum/internet/SpectrumInternet";
import SpectrumTv from "../pages/spectrum/tv/SpectrumTv";
import SpectrumDeals from "../pages/spectrum/deals/SpectrumDeals";
import SpectrumBundles from "../pages/spectrum/bundles/SpectrumBundles";
import SpectrumMobile from "../pages/spectrum/mobile/SpectrumMobile";
import SpectrumChannelLineUps from "../pages/spectrum/channel-lineups/SpectrumChannelLineUps";
import SpectrumVoice from "../pages/spectrum/voice/SpectrumVoice";
import SpectrumAvailability from "../pages/spectrum/availability/SpectrumAvailability";
import SpectrumInternetReview from "../pages/spectrum/internet-review/SpectrumInternetReview";
import SpectrumTvReview from "../pages/spectrum/tv-review/SpectrumTvReview";
import SpectrumInternetCheapPlan from "../pages/spectrum/internet-plan/SpectrumInternetCheapPlan";
import SpectrumBundlesReview from "../pages/spectrum/bundles-review/SpectrumBundlesReview";
import SpectrumMobileReview from "../pages/spectrum/mobile-review/SpectrumMobileReview";

//! COX Pages
import CoxReview from "../pages/cox/review/CoxReview";
import CoxInternetReview from "../pages/cox/internet-review/CoxInternetReview";
import CoxTv from "../pages/cox/tv/CoxTv";
import CoxDeals from "../pages/cox/deals/CoxDeals";
import CoxBundleReview from "../pages/cox/bundle-review/CoxBundleReview";
import CoxChannelLineUp from "../pages/cox/channel-lineup/CoxChannelLineUp";
import CoxAvailability from "../pages/cox/availability/CoxAvailability";
import CoxInstallationGuide from "../pages/cox/installation-guide/CoxInstallationGuide";
import CoxTroubleShootingGuide from "../pages/cox/troubleshooting-guide/CoxTroubleShootingGuide";
import CoxCustomerService from "../pages/cox/customer-service/CoxCustomerService";
import CoxInternet from "../pages/cox/internet/CoxInternet";
import CoxBundles from "../pages/cox/bundles/CoxBundles";

const Routers = () => {
  return (
    <Routes>
      {/* Main Pages */}
      <Route path="/" element={<Home />} />
      <Route path="/tv" element={<Tv />} />
      <Route path="/internet" element={<Internet />} />
      <Route path="/streaming" element={<Streaming />} />
      <Route path="/what-to-watch" element={<WhatToWatch />} />
      <Route path="/resources" element={<Resources />} />

      {/* Xfinity Pages */}
      <Route path="/xfinity" element={<XfinityReview />} />
      <Route path="/xfinity/internet" element={<XfinityInternet />} />
      <Route path="/xfinity/cable-tv" element={<XfinityTv />} />
      <Route path="/xfinity/promotions" element={<XfinityDeals />} />
      <Route path="/xfinity/channel-linup" element={<XfinityChannelLinup />} />
      <Route path="/xfinity/mobile" element={<XfinityMobile />} />
      <Route path="/xfinity/bundles" element={<XfinityBundles />} />
      <Route path="/xfinity/voice" element={<XfinityVoice />} />
      <Route path="/xfinity/availability" element={<XfinityAvailability />} />
      <Route path="/xfinity/hidden-costs" element={<XfinityHiddenCosts />} />
      <Route
        path="/xfinity/phone-number"
        element={<XfinityCustomerService />}
      />
      <Route
        path="/xfinity/internet-review"
        element={<XfinityInternetReview />}
      />
      <Route path="/xfinity/tv-review" element={<XfinityTvReview />} />
      <Route
        path="/xfinity/bundles-review"
        element={<XfinityBundlesReview />}
      />
      
      {/* Spectrum Pages */}
      <Route path="/spectrum" element={<SpectrumReview/>} />
      <Route path="/spectrum/internet" element={<SpectrumInternet/>} />
      <Route path="/spectrum/cabel-tv" element={<SpectrumTv/>} />
      <Route path="/spectrum/promotions" element={<SpectrumDeals/>} />
      <Route path="/spectrum/bundles" element={<SpectrumBundles/>} />
      <Route path="/spectrum/mobile" element={<SpectrumMobile/>} />
      <Route path="/spectrum/channel-lineup" element={<SpectrumChannelLineUps/>} />
      <Route path="/spectrum/voice" element={<SpectrumVoice/>} />
      <Route path="/spectrum/availability" element={<SpectrumAvailability/>} />
      <Route path="/spectrum/internet-review" element={<SpectrumInternetReview/>} />
      <Route path="/spectrum/tv-review" element={<SpectrumTvReview/>} />
      <Route path="/spectrum/internet-cheap-plan" element={<SpectrumInternetCheapPlan/>} />
      <Route path="/spectrum/bundles-review" element={<SpectrumBundlesReview/>} />
      <Route path="/spectrum/mobile-review" element={<SpectrumMobileReview/>} />
      
       {/* Cox Pages */}
       <Route path="/cox" element={<CoxReview/>}/> 
       <Route path="/cox/internet-review" element={<CoxInternetReview/>}/> 
       <Route path="/cox/tv" element={<CoxTv/>}/> 
       <Route path="/cox/deals" element={<CoxDeals/>}/> 
       <Route path="/cox/bundle-review" element={<CoxBundleReview/>}/> 
       <Route path="/cox/channel-lineup" element={<CoxChannelLineUp/>}/> 
       <Route path="/cox/availability" element={<CoxAvailability/>}/> 
       <Route path="/cox/installation-guide" element={<CoxInstallationGuide/>}/> 
       <Route path="/cox/troubleshooting" element={<CoxTroubleShootingGuide/>}/> 
       <Route path="/cox/phone-number" element={<CoxCustomerService/>}/> 
       <Route path="/cox/internet" element={<CoxInternet/>}/> 
       <Route path="/cox/bundles" element={<CoxBundles/>}/>
    </Routes>
  );
};

export default Routers;
