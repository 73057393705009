import React, { useState } from "react";
import "./spectrum-internet-review.scss";
import SubNav from "../../../components/sub-nav/SubNav";
import { spectrumNavLinks } from "../../../assets/data/navLinks/spectrumNavLinks";
import InnerPagesHeroTwo from "../../../components/inner-pages-hero-two/InnerPagesHeroTwo";
import StickyHeader from "../../../components/sticky-header/StickyHeader";
import spectrum from "../../../assets/images/Spectrum.png";
import { GoArrowUpRight } from "react-icons/go";
import reviewmanthree from "../../../assets/images/reviewmanthree.png";
import star from "../../../assets/images/star.png";
import asidespectrum from "../../../assets/images/asidespectrum.png";
import quotations from "../../../assets/images/quotations.svg";
import { IoThumbsDownSharp, IoThumbsUpSharp } from "react-icons/io5";
import { Link } from "react-router-dom";
import quotationsIcon from "../../../assets/images/quotations.svg";
import CTA from "../../../components/cta-section/CTA";
import PackageTable from "../../../components/package-table/PackageTable";
import PackagePricing from "../../../components/package-pricing/PackagePricing";
import wifi from "../../../assets/images/wifi.png";
import Newsletter from "../../../components/newsletter/Newsletter";

const SpectrumInternetReview = () => {
  const [faq, setFaq] = useState();
  let accordionHandler = (i) => {
    if (faq === i) {
      return setFaq(null);
    }

    setFaq(i);
  };
  let heroData = {
    card1: {
      logo: spectrum,
      rating: "(3.45/5)",
      starImg: star,
      desc: "$500 contract buyout when you switch",
      buttonText: "Compare Plans",
      linkPath: "#",
      buttonIcon: <GoArrowUpRight className="check-icon" />,
    },

    card2Img: reviewmanthree,

    cardsStrip: [
      {
        subtitle: "Service provider:",
        title: "spectrum",
        icon: asidespectrum,
      },
      {
        subtitle: "Internet speed:",
        title:
          "Starting at up to 300 Mbps up to 1 Gbps** (wireless speeds may vary)",
        icon: asidespectrum,
      },
      {
        subtitle: "Price:",
        title: "$59.99/mo.",
        icon: asidespectrum,
      },
      {
        subtitle: "Channels",
        title: "Unlimited",
        icon: asidespectrum,
      },
    ],

    tableData: [
      {
        head: [
          "Top Plans",
          "Price* ",
          "Max. download speeds",
          "Standout features",
          "Details",
        ],

        body: [
          {
            content: [
              "1.",
              "Spectrum Internet® 300 Mbps",
              "Starting at $49.99/mo. for 12 mos.*",
              "Up to 300 Mbps (wireless speeds may vary)",
              "No data caps, free modem, access to a nationwide network of Wi-Fi access points, free antivirus software",
            ],
            linkPath: "#",
          },

          {
            content: [
              "2.",
              "Spectrum Internet® Gig",
              "Starting at $89.99/mo. for 12 mos.*",
              "Up to 1 Gbps** (wireless speeds may vary)",
              "No data caps, free modem, access to a nationwide network of Wi-Fi access points, free antivirus software",
            ],
            linkPath: "#",
          },
          {
            content: [
              "3.",
              "Internet 300 Mbps + TV",
              "Starting at $109.98/mo. for 12 mos.*",
              "Up to 300 Mbps (wireless speeds may vary)",
              "Free HD, access to thousands of on-demand titles, free modem",
            ],
            linkPath: "#",
          },
          {
            content: [
              "4.",
              "Internet 300 Mbps + TV + Voice",
              "Starting at $124.97/mo. for 12 mos.*",
              "Up to 300 Mbps (wireless speeds may vary)",
              "125+ HD channels, free modem, unlimited calling in North America",
            ],
            linkPath: "#",
          },
          {
            content: ["", "", "", "", ""],
            linkPath: "#",
          },
        ],
      },
    ],

    bottomDesc:
      "Data effective as of publish date. Offers and availability may vary by location and are subject to change. See full disclaimer.",
  };
  let packageTableData = [
    {
      head: [
        "package",
        "Price",
        "Download speeds",
        "Max. upload speeds",
        "Details",
      ],

      body: [
        {
          content: [
            "1.",
            "Spectrum Internet®",
            "Starting at $49.99/mo. for 12 mos.*",
            "Up to 300 Mbps (wireless speeds may vary)",
            "10 Mbps",
          ],
          linkPath: "#",
        },
        {
          content: [
            "2.",
            "Spectrum Internet Ultra",
            "Starting at $69.99/mo. for 12 mos.*",
            "Up to 500 Mbps (wireless plans may vary)",
            "20 Mbps",
          ],
          linkPath: "#",
        },
        {
          content: [
            "3.",
            "Spectrum Internet® Gig",
            "Starting at $89.99/mo. for 12 mos.*",
            "Up to 1 Gbps** (wireless speeds may vary)",
            "35 Mbps",
          ],
          linkPath: "#",
        },
      ],
    },
  ];
  let packageTableDataTwo = [
    {
      head: ["package", "Price", "Channels", "Details"],

      body: [
        {
          content: [
            "1.",
            "Spectrum TV® Select Signature",
            "$59.99/mo. for 12 mos.",
            "150+ channels",
          ],
          linkPath: "#",
        },
        {
          content: [
            "2.",
            "Mi Plan Latino®",
            "$39.99/mo. for 12 mos.",
            "145+ channels",
          ],
          linkPath: "#",
        },
      ],
    },
  ];

  return (
    <div className="spectrum-internet-review">
      <SubNav links={spectrumNavLinks} />

      <div className="section inner-hero">
        <div className="head">
          <h2 className="sect-heading">
            Spectrum Review 2023: Plans, Prices, And More
          </h2>
          <p className="sect-desc">
            We’ve researched the packages that’ll give you the best internet
            performance and TV channels.
          </p>
        </div>
        <InnerPagesHeroTwo data={heroData} />
      </div>
      <StickyHeader />

      <div className="endnote is-spectrum-good">
        <div className="endnote-container">
          <img src={quotationsIcon} alt="quotations" />
          <h4>Is Spectrum internet good?</h4>
          <p>
            We think{" "}
            <span className="link">
              <Link to={"#"}> Spectrum internet </Link>
            </span>{" "}
            is good for folks who have larger households or want to bundle their
            internet with live TV. And in our recent customer satisfaction
            survey, happy Spectrum customers landed their internet provider in
            4th place for overall satisfaction.
            <br />
            With fast download speeds, no{" "}
            <span className="link">
              <Link to={"#"}>data cap </Link>
            </span>
            , a free modem, and no-contract flexibility, Spectrum has a lot of
            the perks we look for in an internet service provider. But when you
            get into the fine print, Spectrum’s higher starting prices and
            second-year price increases aren’t great for customers.
          </p>
          <br />
          <h4>Spectrum internet pros and cons</h4>
          <div className="custom-table">
            <table>
              <thead>
                <tr>
                  <th>
                    Prospects <IoThumbsUpSharp className="icon" />
                  </th>
                  <th>
                    Consequences <IoThumbsDownSharp className="icon" />
                  </th>
                </tr>
              </thead>

              <tbody>
                <tr>
                  <td>Fast download and upload speeds</td>
                  <td>Higher internet prices</td>
                </tr>
                <tr>
                  <td>No contracts</td>
                  <td>Price increases after 12 months</td>
                </tr>
                <tr>
                  <td>No data caps</td>
                  <td> </td>
                </tr>
                <tr>
                  <td> Free modem</td>
                  <td> </td>
                </tr>
                <tr>
                  <td> $500 contract buyout</td>
                  <td> </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div className="section important-links">
        <div className="head">
          <h2 className="sect-heading">
            Jump to <span>important links</span>
          </h2>
          <p className="sect-desc">
            Lorem ipsum dolor sit amet consectetur. Sit purus quis porttitor
            scelerisque sem tempor netus. Nisi diam pulvinar pharetra lacus in a
            pellentesque nunc consequat. Commodo elit dignissim vulputate
            cursus. Dictum in morbi vestibulum posuere placerat pellentesque
            eros congue molestie.
          </p>
        </div>

        <div className="all-links">
          <Link to={"#"}>Spectrum internet deals</Link>
          <Link to={"#"}>Internet packages</Link>
          <Link to={"#"}>Internet and TV bundles</Link>
          <Link to={"#"}>Internet speeds</Link>
          <Link to={"#"}>Free internet</Link>
          <Link to={"#"}>Spectrum vs. the competition</Link>
        </div>
      </div>

      <CTA
        heading={"Find out if Spectrum internet is available in your area"}
        desc={
          "Enter your zip code below to see if Spectrum is in your town. If it is, you’ll be able to see the specific service types available to you."
        }
      />

      <div className="section spectrum-internet-price-and-packages">
        <div className="head">
          <h2 className="sect-heading">
            Spectrum internet <span> prices and packages</span>
          </h2>
          <p className="sect-desc">
            Lorem ipsum dolor sit amet consectetur. Sit purus quis porttitor
            scelerisque sem tempor netus. Nisi diam pulvinar pharetra lacus in a
            pellentesque nunc consequat. Commodo elit dignissim vulputate
            cursus. Dictum in morbi vestibulum posuere placerat pellentesque
            eros congue molestie.
          </p>
        </div>
        <div className="package-table-container channels">
          <h4>Spectrum internet deals</h4>
          <p className="after-heading">
            Spectrum offers promotions to encourage folks to sign up for its
            internet service. Here are a few important ones, and you can find
            the rest on our complete
            <span className="link">
              <Link to={"#"}> Spectrum deals review: </Link>
            </span>{" "}
          </p>
          <ul>
            <li>
              {" "}
              · 90-day{" "}
              <span className="link">
                <Link to={"#"}> Peacock Premium </Link>
              </span>
              subscription ($4.99/mo. value)
            </li>
            <li> · Free internet modem</li>
            <li> · Contract buyout up to $500</li>
          </ul>
        </div>

        <div className="package-table-container channels">
          <h4>Spectrum internet prices and packages</h4>
          <p className="after-heading">
            The{" "}
            <span className="link">
              <Link to={"#"}> Spectrum Internet package </Link>
            </span>{" "}
            is Spectrum’s entry-level offering with a maximum download speed of
            up to 300 Mbps (wireless speeds may vary).
            <br />
            The entry-level package isn’t bad, especially considering Spectrum
            just raised its speed up from 200 Mbps without changing the price.
            This package can be a potential fit for a family of moderate
            internet users, especially if you’re working from home.
            <br />
            <span className="link">
              <Link to={"#"}>Spectrum Internet 500 Mbps</Link>
            </span>{" "}
            offers even faster performance with maximum download speeds of up to
            500 Mbps (wireless speeds may vary).
            <br />
            If we had to choose, we’d pick Spectrum Internet 500 Mbps as the
            best Spectrum Internet package. With its high speeds, your internet
            connections will remain just as fast even if you have kids or
            housemates who regularly stream TV shows or game online, and it’s a
            more affordable option than Spectrum Internet Gig.
            <br />
            <span className="link">
              <Link to={"#"}>Spectrum’s top-tier Internet Gig package</Link>
            </span>{" "}
            comes with maximum download speeds of up to 1 Gbps** (wireless
            speeds may vary). With Spectrum gig internet service, your internet
            performance won’t collapse if your household has multiple people who
            regularly stream 4K videos, download large files, and take lengthy
            video calls.
            <br />
            On all three speed tiers, your monthly price will increase by $25
            once Spectrum’s one-year promotional rate ends. Even though a lot of
            ISPs have similar fine print, we’re still not fans of the one-year
            price hike. We recommend marking down your promo price end date to
            make sure you’re not surprised, or try negotiating with Spectrum to
            bring your price back down.
            <br />
            Promotional rates aren’t the only way to save with Spectrum
            internet. Check out our Spectrum deals and promotions review for
            more ways to get a better deal with Spectrum.
          </p>
          <PackageTable item={packageTableData} />
          <p className="below-table">
            Data effective as of publish date. Offers and availability may vary
            by location and are subject to change. See full disclaimer.
          </p>
        </div>
        <div className="endnote review-endnote">
          <div className="endnote-container">
            <img className="quotation-img" src={quotations} alt="quotations" />
            <h4>Our Spectrum internet bill</h4>
            <p>
              CableTV.com team member Manuel chose the Spectrum Internet Gig
              plan to keep him and his wife connected as they both work from
              home. Here’s what he has to say about how his internet bill
              compares to his other expenses for his Florida home:
              <br />
              “I pay about $110 a month for Spectrum internet, which isn’t
              necessarily a deal, but isn’t robbing me either. Some quick, rough
              math shows that my Spectrum service makes up about 15% of my
              typical utility bills, which I feel is an okay percentage.”
            </p>
          </div>
        </div>
      </div>

      <div className="divider">
        <p>
          Pro tip: Spectrum recently raised its download speeds for its cheapest
          two tiers, which means existing customers automatically receive faster
          internet. You might need to reset your modem first, though, which you
          can do remotely through Spectrum’s website.
        </p>
      </div>

      <div className="section spectrum-tv-and-bundles">
        <div className="head">
          <h2 className="sect-heading">
            Spectrum <span> TV and internet </span> bundles
          </h2>
          <p className="sect-desc">
            We recommend Spectrum bundles over internet-only packages because
            Spectrum cable TV is as affordable as or cheaper than most{" "}
            <span className="link">
              <Link to={"#"}>live TV streaming services</Link>
            </span>{" "}
            and and comes with more channels.
            <br />
            Check out our Spectrum TV and Spectrum bundles reviews to learn
            more. And don’t forget that you can watch Spectrum TV on the go with
            the Spectrum TV app.
            <br />
            Check out our Spectrum TV and Spectrum bundles reviews to learn
            more. And don’t forget that you can watch Spectrum TV on the go with
            the Spectrum TV app.
          </p>
          <PackageTable item={packageTableData} />
          <p className="below-table">
            Data effective as of publish date. Offers and availability may vary
            by location and are subject to change. See full disclaimer.
          </p>
        </div>
      </div>

      <div className="section spectrum-internet-speed">
        <div className="head">
          <h2 className="sect-heading">
            Spectrum <span>internet speeds</span>
          </h2>
          <p className="sect-desc">
            Spectrum has three internet speed tiers: up to 300 Mbps, 500 Mbps,
            and 1 Gbps** (wireless speeds may vary). These speeds and prices are
            pretty on par with most other cable providers. That said, there are
            better deals out there, depending on where you call home.
            <br />
            To learn more about what those speeds mean for your home internet,
            check out our{" "}
            <span className="link">
              <Link to={"#"}>internet speed guide</Link>
            </span>{" "}
            or refer to the highlights below.
          </p>
        </div>
        <PackagePricing />
      </div>

      <div className="section spectrum-wifi">
        <div className="head">
          <h2 className="sect-heading">
            Spectrum <span> Wi-Fi</span>
          </h2>
          <p className="sect-desc">
            All of Spectrum’s internet plans include a free internet modem. But
            because nothing’s totally free in life, Spectrum also offers a Wi-Fi
            router rental for $5 per month. If you want to wirelessly connect
            devices to the internet, you’ll need a router to convert the signal
            into a Wi-Fi connection. For new subscribers, Spectrum’s free modem
            is a nice convenience and removes one more thing you have to buy
            from the electronics store. But you’ll have to return the modem once
            you end Spectrum internet service. <br />
            If you’re going to stick with Spectrum for the long haul, we’d
            recommend springing for your own wireless router and modem or a
            combo Wi-Fi router/modem.
            <br />
            By buying your own router, you won’t have to pay unnecessary rental
            fees that can add up, and you can get models with faster wireless
            speeds than Spectrum would offer you. If you get your own modem, you
            can also keep it if you ever change cable internet ISPs down the
            road. If you’d like to buy your own equipment for Spectrum internet,
            check out our suggestions for the best{" "}
            <span className="link">
              <Link to={"#"}>Spectrum-compatible modems </Link>
            </span>
            and wireless routers to see our hardware picks.
          </p>
        </div>
        <img src={wifi} alt="wifi" className="wifi" />
        <div className="endnote review-endnote">
          <div className="endnote-container">
            <img className="quotation-img" src={quotations} alt="quotations" />
            <h4>Our Spectrum Wi-Fi experience</h4>
            <p>
              Manuel uses Spectrum’s free modem, decided to get his own Wi-Fi
              router. Here’s his experience:
              <br />
              “The Spectrum modem works just fine for me. Our hookup is in an
              indoor closet, which keeps it out of the way. It needs an
              occasional reset (probably once every six weeks or so), but I
              don’t mind that much.
              <br />
              “On the other hand, I started out using Spectrum’s Wi-Fi router
              but wasn’t a fan. I had a lot of dead spots in my home, and it
              required a lot more restarts the modem—I had to fiddle with it
              twice every week. So I switched to using two Google Nest routers,
              and I’ve had no issues since.”
            </p>
          </div>
        </div>
      </div>

      <div className="section internet-features">
        <div className="head">
          <h2 className="sect-heading">
            Spectrum <span> internet features </span>
          </h2>
          <p className="sect-desc">
            Lorem ipsum dolor sit amet consectetur. Sit purus quis porttitor
            scelerisque sem tempor netus. Nisi diam pulvinar pharetra lacus in a
            pellentesque nunc consequat. Commodo elit dignissim vulputate
            cursus. Dictum in morbi vestibulum posuere placerat pellentesque
            eros congue molestie.
          </p>
        </div>

        <div className="package-table-container channels">
          <h4>Spectrum WiFi hotspots</h4>
          <p className="after-heading">
            If your home ever has an internet outage right before a big video
            call, or if you’re out running errands and don’t want to burn
            through your smartphone’s mobile data cap, you can jump onto
            <span className="link">
              <Link to={"#"}> Spectrum’s WiFi hotspot </Link>
            </span>{" "}
            network to get back online.
            <br />
            Spectrum internet subscribers get free access to Spectrum’s
            Out-of-Home WiFi nationwide, which includes Wi-Fi internet networks
            at local businesses. The access point network is{" "}
            <span className="link">
              <Link to={"#"}> available across 41 states </Link>
            </span>{" "}
            in cities including San Francisco, Denver, and New York City.
            <br />
            Wi-Fi networks may be a pretty common perk for internet providers,
            but they’re incredibly useful. We recommend you keep your Spectrum
            account information handy to log in while you’re on-the-go.
          </p>
        </div>
        <div className="package-table-container channels">
          <h4>No contracts </h4>
          <p className="after-heading">
            Once you sign the dotted line at most ISPs, it’ll usually take a
            giant fee or more maneuvering than an Ocean’s Eleven heist to break
            your contract.
            <br />
            Spectrum doesn’t have internet contracts, so you won’t be required
            to stay with the ISP for a full year. Without contracts, you’ll have
            the flexibility to sign up for Spectrum even if you need the
            internet for only a few months, and then you can cancel without any
            early termination fees.
            <br />
            If you’re locked in an internet or TV contract with another
            provider, Spectrum will help buyout your contract up to $500◊
            through their Contract Buyout Program. To qualify for the promotion,
            you’ll just need to sign up for a qualifying
            <span className="link">
              <Link to={"#"}> Spectrum bundle. </Link>
            </span>
          </p>
        </div>
        <div className="package-table-container channels">
          <h4>No data caps </h4>
          <p className="after-heading">
            Between streaming video services like{" "}
            <span className="link">
              <Link to={"#"}>Hulu </Link>
            </span>{" "}
            and devices like smart home cameras, it’s easier than ever to use up
            more internet data than you’d expect.
            <br />
            Fortunately, Spectrum has no data caps, so your household can use
            the internet without having to keep an eye on Spectrum’s data meter.
            You get to pass on the monthly 1 TB data cap and overage fees other
            ISPs charge.
            <br />
            With Spectrum, you won’t have to worry about paying extra if you
            have kids streaming Paw Patrol all day, every day. That’s a doggone
            relief.
          </p>
        </div>
        <div className="package-table-container channels">
          <h4>Parental controls </h4>
          <p className="after-heading">
            It’s nice to have some extra online protection for your family, and
            Spectrum delivers in this regard.
            <br />
            To keep your kids safe on the internet, Spectrum offers subscribers
            a free Security Suite application for Windows and Apple computers.
            With Security Suite, you can block sites based on their content,
            filter adult sites from search results, set computer and web
            browsing time limits, and track your kids’ online activity.
            <br />
            On Spectrum’s email service (which is different from services like
            Gmail and Yahoo Mail), you can block emails from specific people or
            websites. We still prefer using our Gmail accounts, though—Gmail’s
            block function works just fine for us.
          </p>
        </div>
        <div className="package-table-container channels">
          <h4>Spectrum internet for gaming </h4>
          <p className="after-heading">
            Our{" "}
            <span className="link">
              <Link to={"#"}> “Best Internet Provider for Gaming” </Link>
            </span>{" "}
            review showed that Spectrum has higher latency than providers like
            Verizon Fios, Optimum, Xfinity, and Frontier. While this may mean
            you won’t always be the quickest on the trigger, Spectrum internet
            plans have maximum download speeds that easily accommodate online
            gaming.
            <br />
            The Federal Communications Commission recommends a minimum download
            speed of up to 25 Mbps if you’re part of a typical four- or
            five-person household. With plans that start at up to 300 Mbps
            (wireless speeds may vary), Spectrum can handle your gaming sessions
            plus everyone’s web browsing or video watching.
            <br />
            But if you’re a frequent online gamer who wants to avoid lag, check
            out our online gaming guide to learn how to optimize your Spectrum
            internet connection before the next Counter-Strike: Global Offensive
            or League of Legends night with your friends.
          </p>
        </div>
        <div className="package-table-container channels">
          <h4> Free Spectrum internet </h4>
          <p className="after-heading">
            If your family needs an extra boost to get internet at home, look
            into the FCC’s Affordable Connectivity Program (ACP). If you
            participate in certain federal assistance programs and/or your
            household income is less than or equal to 200% of the federal
            poverty guidelines, you can get $30 per month off internet.
            <br />
            But since Spectrum’s lowest plan costs more than $30, you should
            also look into the Spectrum Internet Assist program if you want free
            internet. This program offers 30 Mbps download speeds for around
            $14.99 per month, and this offer can be paired with the ACP to make
            your internet service completely free.
            <br />
            Head over to our{" "}
            <span className="link">
              <Link to={"#"}> free and low-income internet guide </Link>
            </span>{" "}
            to learn more about these internet assistance programs and many
            more.
          </p>
        </div>
        <div className="package-table-container channels">
          <h4> Spectrum vs. the competition </h4>
          <p className="after-heading">
            In Our
            <span className="link">
              <Link to={"#"}>
                {" "}
                2022 internet customer satisfaction survey,{" "}
              </Link>{" "}
            </span>
            Spectrum held its own against 13 other competitors:
          </p>
          <ul>
            <li>· Placed in the Top 5 for every major category</li>
            <li>· Placed 4th for overall customer satisfaction</li>
            <li>
              · Placed 3rd in internet speed satisfaction, after Xfinity and
              Verizon Fios
            </li>
            <li>
              · Placed 3rd in customer support satisfaction, after Verizon Fios
              and AT&T Internet
            </li>
          </ul>
          <p className="after-heading">
            But internet costs are a sore subject for many, and not all Spectrum
            customers felt that they were getting their money’s worth. Of the
            customers surveyed, 47% felt Spectrum offers you good bang for your
            buck, while 21% said the price was poor or terrible given the
            quality.
            <br />
            That mixed judgment aligns with our review of Spectrum internet
            prices, which are statistically higher than average. But only you
            can decide if the fast speeds and free modem—plus lack of annual
            contracts and data caps—make up that value for you.
          </p>
        </div>
      </div>

      <div className="section final-take">
        <div className="head">
          <h2 className="sect-heading">
            Final take: <span>Is Spectrum internet worth it?</span>
          </h2>
          <p className="sect-desc">
            Between its starting prices and price hikes after a year, Spectrum
            isn’t the cheapest internet option on the market. But at the same
            time, you’ll be getting a bunch of benefits like no-contract
            agreements, no data caps, a free modem, and access to Spectrum’s
            Out-of-Home WiFi network.
            <br />
            You’ll definitely be paying for all of these features, but Spectrum
            internet will work well for your household if performance is more
            important to you than your monthly bill or if you want to bundle
            internet with live TV.
          </p>
        </div>

        <div className="all-links">
          <Link to={"#"}>Sign up for Spectrum</Link>
        </div>
      </div>

      <div className="section spectrum-faq">
        <div className="head">
          <h2 className="sect-heading">
            Spectrum <span> internet</span> FAQ
          </h2>

          <p className="sect-desc">
            TL;DR? Xfinity kills it. For more detailed info on all Xfinity
            equipment and services, see our comprehensive Xfinity equipment
            guide.
          </p>
        </div>

        <div className="faq-wrapper">
          <div className="accordion">
            <div className="item">
              <h3 className="title" onClick={() => accordionHandler(1)}>
                <span>{faq === 1 ? "-" : "+"}</span> How much is Spectrum
                internet?
              </h3>

              <div className={`faq-content ${faq === 1 ? "show" : ""}`}>
                <p>
                  Spectrum Internet starts at $49.99 per month for 12 months*
                  for an up to 300 Mbps (wireless speeds may vary) internet
                  plan. Spectrum Internet Ultra starts at $69.99 per month for
                  12 months for an up to 500 Mbps (wireless speeds may vary)
                  internet plan. Spectrum Internet Gig starts at $89.99 per
                  month for 12 months for an up to 1 Gbps (wireless speeds may
                  vary) internet plan.
                </p>
              </div>
            </div>
          </div>

          <div className="accordion">
            <div className="item">
              <h3 className="title" onClick={() => accordionHandler(2)}>
                <span>{faq === 2 ? "-" : "+"}</span> What is the lowest price
                for Spectrum internet?
              </h3>

              <div className={`faq-content ${faq === 2 ? "show" : ""}`}>
                <p>
                  Spectrum Internet is Spectrum's cheapest internet plan at
                  $49.99 per month. You'll get maximum download and upload
                  speeds of up to 300 Mbps and up to 10 Mbps (wireless speeds
                  may vary).
                </p>
              </div>
            </div>
          </div>

          <div className="accordion">
            <div className="item">
              <h3 className="title" onClick={() => accordionHandler(3)}>
                <span>{faq === 3 ? "-" : "+"}</span>Can I just have internet
                with Spectrum?
              </h3>

              <div className={`faq-content ${faq === 3 ? "show" : ""}`}>
                <p>
                  Spectrum offers standalone internet service packages for
                  between $49.99 to $89.99 per month for the first 12 months.
                </p>
              </div>
            </div>
          </div>

          <div className="accordion">
            <div className="item">
              <h3 className="title" onClick={() => accordionHandler(4)}>
                <span>{faq === 4 ? "-" : "+"}</span> What is the cost of
                Spectrum internet only?
              </h3>

              <div className={`faq-content ${faq === 4 ? "show" : ""}`}>
                <p>
                  The price for internet-only Spectrum packages is between
                  $49.99 and $89.99 per month depending on your internet speed
                  requirements.
                </p>
              </div>
            </div>
          </div>

          <div className="accordion">
            <div className="item">
              <h3 className="title" onClick={() => accordionHandler(5)}>
                <span>{faq === 5 ? "-" : "+"}</span>What is the cost of Spectrum
                internet after 12 months?
              </h3>

              <div className={`faq-content ${faq === 5 ? "show" : ""}`}>
                <p>
                  After 12 months, Spectrum increases your internet bill by $25
                  per month.
                </p>
              </div>
            </div>
          </div>

          <div className="accordion">
            <div className="item">
              <h3 className="title" onClick={() => accordionHandler(6)}>
                <span>{faq === 6 ? "-" : "+"}</span> What kind of internet is
                Spectrum?
              </h3>

              <div className={`faq-content ${faq === 6 ? "show" : ""}`}>
                <p>
                  Spectrum is a cable internet provider with download speeds of
                  up to 1 Gbps" (wireless speeds may vary).
                </p>
              </div>
            </div>
          </div>

          <div className="accordion">
            <div className="item">
              <h3 className="title" onClick={() => accordionHandler(7)}>
                <span>{faq === 7 ? "-" : "+"}</span> Is the Spectrum modem
                really free?
              </h3>

              <div className={`faq-content ${faq === 7 ? "show" : ""}`}>
                <p>
                  Yes, the Spectrum modem is really free. Not too shabby, eh?
                </p>
              </div>
            </div>
          </div>

          <div className="accordion">
            <div className="item">
              <h3 className="title" onClick={() => accordionHandler(8)}>
                <span>{faq === 8 ? "-" : "+"}</span> How much does Spectrum
                Wi-Fi cost?
              </h3>

              <div className={`faq-content ${faq === 8 ? "show" : ""}`}>
                <p>
                  Spectrum offers its Wi-Fi router for $5 per month. This is
                  cheaper than most other providers, who charge between $10 and
                  $15 per month for internet equipment.
                </p>
              </div>
            </div>
          </div>

          <div className="accordion">
            <div className="item">
              <h3 className="title" onClick={() => accordionHandler(8)}>
                <span>{faq === 8 ? "-" : "+"}</span> Are Spectrum internet plans
                unlimited?
              </h3>

              <div className={`faq-content ${faq === 8 ? "show" : ""}`}>
                <p>
                  Every Spectrum internet plan comes offers unlimited data (no
                  data caps). Spectrum internet plans also don't require
                  contracts, so you're free to leave Spectrum service without
                  facing a monetary penalty.
                </p>
              </div>
            </div>
          </div>

          <div className="accordion">
            <div className="item">
              <h3 className="title" onClick={() => accordionHandler(9)}>
                <span>{faq === 9 ? "-" : "+"}</span> Is Spectrum internet the
                same as Wi-Fi?
              </h3>

              <div className={`faq-content ${faq === 9 ? "show" : ""}`}>
                <p>
                  To get Wi-Fi with Spectrum internet, you can rent a wireless
                  router from Spectrum for $5 per month. You can also buy your
                  own Wi-Fi router, which typically costs between $40 to $120.
                  Check out our wireless router recommendations to find a model
                  that best fits your needs.
                </p>
              </div>
            </div>
          </div>

          <div className="accordion">
            <div className="item">
              <h3 className="title" onClick={() => accordionHandler(10)}>
                <span>{faq === 10 ? "-" : "+"}</span> I'm having a Spectrum
                internet problem. What should I do?
              </h3>

              <div className={`faq-content ${faq === 10 ? "show" : ""}`}>
                <p>
                  Check out our Spectrum troubleshooting guide for quick fixes
                  to everyday problems you might encounter with Spectrum
                  internet.
                </p>
              </div>
            </div>
          </div>

          <div className="accordion">
            <div className="item">
              <h3 className="title" onClick={() => accordionHandler(11)}>
                <span>{faq === 11 ? "-" : "+"}</span> Are Spectrum and Charter
                Communications the same company?
              </h3>

              <div className={`faq-content ${faq === 11 ? "show" : ""}`}>
                <p>
                  Charter Communications offers internet, TV, voice, and mobile
                  services under the brand name Spectrum. Because of this
                  connection, you might hear some people refer to Spectrum as
                  Charter Spectrum.
                </p>
              </div>
            </div>
          </div>

          <div className="accordion">
            <div className="item">
              <h3 className="title" onClick={() => accordionHandler(12)}>
                <span>{faq === 12 ? "-" : "+"}</span>What is the best Internet
                that Spectrum offers?
              </h3>

              <div className={`faq-content ${faq === 12 ? "show" : ""}`}>
                <p>
                  Spectrum's fastest internet package is Spectrum Internet Gig,
                  which provides up to 1 Gbps download speeds and 20 Mbps upload
                  speeds for $89.99 per month.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="divider">
        <p>See also: Spectrum Internet | Spectrum TV | Spectrum Bundles</p>
      </div>

      <div className="section endnote spectrum-endnote">
        <div className="endnote-container">
          <img className="quotation-img" src={quotations} alt="quotations" />
          <h4>Methodology:</h4>
          <p>
            Our experts spent hundreds of hours diving deep beyond the prices
            and speeds for Spectrum internet—we rated it based on bang for your
            buck, reliability, features, and 
            <span className="link">
              <Link to={"#"}> customer satisfaction </Link>
            </span>{" "}
            <br/>
            To learn more about our methodology, check out our  <span className="link">
              <Link to={"#"}>How We Rank </Link>
            </span> page.
          </p>
          <h4>Related Articles:</h4>
          <ul>
            <li>
              ·{" "}
              <span className="link">
                <Link to={"#"}> AT&T Fiber vs. Spectrum Internet </Link>
              </span>{" "}
            </li>
            <li>
              ·{" "}
              <span className="link">
                <Link to={"#"}> Spectrum vs. Cox Internet </Link>
              </span>{" "}
            </li>
            <li>
              ·{" "}
              <span className="link">
                <Link to={"#"}> Verizon Fios vs. Spectrum Internet </Link>
              </span>{" "}
            </li>
            <li>
              ·{" "}
              <span className="link">
                <Link to={"#"}> The Hidden Costs of Spectrum </Link>
              </span>{" "}
            </li>
            <li>
              ·{" "}
              <span className="link">
                <Link to={"#"}> Spectrum Packages for Seniors</Link>
              </span>{" "}
            </li>
          </ul>
          <h4>Disclaimer:</h4>
          <p>
            <span className="bold"> pectrum Internet: </span> Limited time
            offer; subject to change; valid to qualified residential customers
            who have not subscribed to any services within the previous 30 days
            and who have no outstanding obligation to Charter. Spectrum Internet
            ® promotion price is $49.99/mo; standard rates apply after yr. 1.
            Taxes, fees and surcharges extra and subject to change during and
            after the promotional period; installation/network activation,
            equipment and additional services are extra. General Terms:
            INTERNET: Speed based on wired connection and may vary by address.
            Wireless speeds may vary. Services subject to all applicable service
            terms and conditions, subject to change. Services not available in
            all areas. Restrictions apply. Enter your address to determine
            availability. ©2022 Charter Communications.
          </p>
          <p>
            <span className="bold"> pectrum Internet 500 Mbps: </span> For 12
            months when bundled. Limited time offer; subject to change; valid to
            qualified residential customers who have not subscribed to any
            services within the previous 30 days and who have no outstanding
            obligation to Charter.
          </p>
          <p>
            <span className="bold"> Spectrum Internet Gig </span>
            Limited time offer; subject to change; valid to qualified
            residential customers who have not subscribed to any services within
            the previous 30 days and who have no outstanding obligation to
            Charter. Spectrum Internet® Gig promotion price is $89.99/mo;
            standard rates apply after yr. 1. Taxes, fees and surcharges extra
            and subject to change during and after the promotional period;
            installation/network activation, equipment and additional services
            are extra. General Terms: INTERNET: Speed based on wired connection
            and may vary by address. Wireless speeds may vary. Services subject
            to all applicable service terms and conditions, subject to change.
            Services not available in all areas. Restrictions apply. Enter your
            address to determine availability. ©2022 Charter Communications.
          </p>
          <p>
            <span className="bold">† Spectrum Internet + TV Select</span>
            Limited time offer; subject to change; valid to qualified
            residential customers who have not subscribed to any services within
            the previous 30 days and who have no outstanding obligation to
            Charter. Spectrum TV® Select promotion price is $59.99/mo; standard
            rates apply after yr. 1. Spectrum Internet® promotion price is
            $49.99/mo; standard rates apply after yr. 1. Taxes, fees and
            surcharges (broadcast surcharge up to $21.00/mo) extra and subject
            to change during and after the promotional period;
            installation/network activation, equipment and additional services
            are extra. General Terms: INTERNET: Speed based on wired connection
            and may vary by address. Wireless speeds may vary. TV: TV equipment
            required; charges may apply. Channel and HD programming availability
            based on level of service. Account credentials may be required to
            stream some TV content online. Services subject to all applicable
            service terms and conditions, subject to change. Services not
            available in all areas. Restrictions apply. Enter your address to
            determine availability. ©2022 Charter Communications.
          </p>
          <p>
            <span className="bold"> Spectrum Internet + Voice: </span> Limited
            time offer; subject to change; valid to qualified residential
            customers who have not subscribed to any services within the
            previous 30 days and who have no outstanding obligation to Charter.
            Spectrum Internet® promotion price is $49.99/mo; standard rates
            apply after yr. 1. Spectrum Voice®: Price is $14.99/mo when bundled.
            Taxes, fees and surcharges extra and subject to change during and
            after the promotional period; installation/network activation,
            equipment and additional services are extra. General Terms:
            INTERNET: Speed based on wired connection and may vary by address.
            Wireless speeds may vary. HOME PHONE: Unlimited calling includes
            calls within the U.S., Canada, Mexico, Puerto Rico, Guam, the Virgin
            Islands and more. Services subject to all applicable service terms
            and conditions, subject to change. Services not available in all
            areas. Restrictions apply. Enter your address to determine
            availability. ©2022 Charter Communications.
          </p>
          <p>
            <span className="bold">
              {" "}
              Spectrum Internet + TV Select + Voice{" "}
            </span>{" "}
            Limited time offer; subject to change; valid to qualified
            residential customers who have not subscribed to any services within
            the previous 30 days and who have no outstanding obligation to
            Charter. Spectrum TV® Select promotion price is $59.99/mo; standard
            rates apply after yr. 1. Spectrum Internet® promotion price is
            $49.99/mo; standard rates apply after yr. 1. Spectrum Voice®: Price
            is $14.99/mo when bundled. Taxes, fees and surcharges (broadcast
            surcharge up to $21.00/mo) extra and subject to change during and
            after the promotional period; installation/network activation,
            equipment and additional services are extra. General Terms:
            INTERNET: Speed based on wired connection and may vary by address.
            Wireless speeds may vary. TV: TV equipment required; charges may
            apply. Channel and HD programming availability based on level of
            service. Account credentials may be required to stream some TV
            content online. HOME PHONE: Unlimited calling includes calls within
            the U.S., Canada, Mexico, Puerto Rico, Guam, the Virgin Islands and
            more. Services subject to all applicable service terms and
            conditions, subject to change. Services not available in all areas.
            Restrictions apply. Enter your address to determine availability.
            ©2022 Charter Communications.
          </p>
          <p>
            To qualify for the Contract Buyout Program, a customer must order
            and install a qualifying Triple Play or limited Double Play
            promotion; offers not available in all areas. Offer available to
            qualifying customers only who have no outstanding obligations to
            Charter. Payment amount will be determined by the Early Termination
            Fee on the final bill from the previous provider, not to exceed
            $500. For Contract Buyout qualifications, go to Spectrum.com/buyout.
          </p>
          <p>
            **Speed based on wired connection. Available Internet speeds may
            vary by address. Gig capable modem required for Gig speed. For a
            list of Gig capable modems, visit Spectrum.net/modem.
          </p>
        </div>
      </div>
      <Newsletter />
    </div>
  );
};

export default SpectrumInternetReview;
